$gap-sizes: 2 4 8 12 16 24;

.fx {
  display: flex !important;

  &--row {
    flex-direction: row;
  }

  &--row-rev {
    flex-direction: row-reverse;
  }

  &--col {
    flex-direction: column;
  }

  &--col-rev {
    flex-direction: column-reverse;
  }

  &--wrap {
    flex-wrap: wrap;
  }

  &--center {
    justify-content: center;
    align-items: center;
    align-content: center
  }

  &__just {
    &--start {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--end {
      justify-content: flex-end;
    }

    &--space-around {
      justify-content: space-around;
    }

    &--space-between {
      justify-content: space-between;
    }

    &--space-evenly {
      justify-content: space-evenly;
    }
  }

  &__align {
    &--start {
      align-items: flex-start;
      align-content: flex-start;
    }

    &--center {
      align-items: center;
      align-content: center;
    }

    &--end {
      align-items: flex-end;
      align-content: flex-end;
    }

    &--baseline {
      align-items: baseline;
      align-content: stretch
    }
  }

  &__gap {
    @each $size in $gap-sizes {
      &--#{$size} {
        gap: #{$size}px;
      }
    }
  }

  &__row-gap {
    @each $size in $gap-sizes {
      &--#{$size} {
        gap: #{$size}px;
      }
    }
  }
}

.fx-fill {
  margin: 0;
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}

@mixin fx-flex-px($px) {
  flex-basis: $px;
  min-width: $px;
  max-width: $px;
}

@mixin fx-flex-prc($prc) {
  flex-basis: 100%;
  max-width: $prc;
}

.fx-flex {
  flex: 1 1 0;

  &--40px {
    @include fx-flex-px(40px);
  }

  &--54px {
    @include fx-flex-px(54px);
  }

  &--56px {
    @include fx-flex-px(56px);
  }

  &--80px {
    @include fx-flex-px(80px);
  }

  &--120px {
    @include fx-flex-px(120px);
  }

  &--30 {
    @include fx-flex-prc(30%);
  }

  &--70 {
    @include fx-flex-prc(70%);
  }

  &--none {
    flex: 0 0 auto;
  }

  &--noshrink {
    flex-shrink: 0;
    flex-basis: auto;
  }
}

.fx-grow {
  flex-grow: 1;
}
