.mat-mdc-icon-button {
  .mat-mdc-button-wrapper {
    display: inline-block;
  }

  .mat-icon.mat-icon-inline {
    line-height: 1;

    svg {
      display: block;
      height: 1em;
      width: 1em;
      font-size: 1em;
    }
  }
}

a.mdc-button {
  text-decoration: none;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
