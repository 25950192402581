@import 'variables';

.mat-mdc-table {
  mat-row.mat-mdc-row {
    border: 1px solid $border-gray;
    transition: background-color 0.125s ease-in-out;
    background-color: #FFFFFF;

    &:hover {
      background-color: lighten($border-gray, 5%);
    }

    &--conflict {
      background-color: $red-light;
    }

    &--expandable {
      &.mat-mdc-row--expanded {
        border-bottom: none;
      }
    }

    &--expansion {
      background-color: #ebebeb;
      border-top: none;

      &:not(.mat-mdc-row--expanded) {
        display: none;
      }

      &:hover {
        background-color: #ebebeb;
      }
    }

    mat-cell.mdc-data-table__cell {
      border-bottom: none;
    }
  }
}
