@import 'variables';

.text {
  &--gray {
    color: $text-gray;
  }

  &--light-gray {
    opacity: 0.47;
    color: $text-light-gray;
  }

  &--blue {
    color: $text-blue;
  }

  &--red-dark {
    color: $red-dark;
  }

  &--red {
    color: $red;
  }

  &-10-400 {
    font-size: 10px;
    line-height: 16px;
    font-weight: 400;
  }

  &-10-500 {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
  }

  &-12-400 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.09px;
    line-height: 14px;
  }

  &-12-500 {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.09px;
    line-height: 14px;
  }

  &-14-400 {
    font-size: 14px;
    letter-spacing: 0.25px;
    line-height: 20px;
  }

  &-14-500 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 20px;
  }

  &-16-400 {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
  }

  &-16-500 {
    font-size: 16px;
    letter-spacing: 0.15px;
    line-height: 24px;
    font-weight: 500;
  }

  &-20-500 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.3px;
    line-height: 24px;
  }

  &-24-500 {
    font-size: 24px;
    letter-spacing: 0.3px;
    line-height: 28px;
  }

  &-center {
    text-align: center;
  }
}

.nowrap {
  white-space: nowrap;
}
