// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$baltec-gui-primary: mat.define-palette(mat.$indigo-palette);
$baltec-gui-accent: mat.define-palette(mat.$gray-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$baltec-gui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$baltec-gui-theme: mat.define-light-theme((
  color: (
    primary: $baltec-gui-primary,
    accent: $baltec-gui-accent,
    warn: $baltec-gui-warn
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($baltec-gui-theme);

/* You can add global styles to this file, and also import other style files */

@import './scss/variables';
@import './scss/margin';
@import './scss/padding';
@import './scss/text';
@import './scss/background';
@import './scss/button';
@import './scss/table';
@import './scss/icons';
@import './scss/flex';
@import './scss/grid';
@import './scss/dialog';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.container {
  max-width: 1280px;
  margin: auto;
}

//Set of necessary rules for correct MatIcon alignment

.mat-mdc-tab-link {
  text-decoration: none;
}

button.mat-mdc-menu-item {
  line-height: 24px !important;
}

a.mat-mdc-menu-item > mat-icon {
  margin-bottom: 14px;
}

.w-100 {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.modal-without-padding {
  .mat-mdc-dialog-container {
    padding: 0;

    .mat-mdc-dialog-content {
      padding: 0;
      margin: 0;
    }
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing.dark-backdrop {
  background: #000;
  opacity: 0.25 !important;
}

.date-range-field .mdc-text-field {
  overflow: visible;
}
