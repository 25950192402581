.gd {
  display: grid !important;

  &__row-gap {
    &--8 {
      grid-row-gap: 8px;
    }

    &--16 {
      grid-row-gap: 16px;
    }
  }

  &__col-gap {
    &--12 {
      grid-column-gap: 12px;
    }

    &--16 {
      grid-column-gap: 16px;
    }
  }

  &__cols {
    &--2 {
      grid-template-columns: 1fr 1fr;
    }

    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &--4 {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    &--1-2 {
      grid-template-columns: 1fr 2fr;
    }

    &--2-1 {
      grid-template-columns: 2fr 1fr;
    }

    &--3-2-1 {
      grid-template-columns: 3fr 2fr 1fr;
    }

    &--lt-sm-1 {
      @media screen and (max-width: 599px) {
        grid-template-columns: 1fr;
      }
    }

    &--lt-sm-2 {
      @media screen and (max-width: 599px) {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  &__col-all {
    grid-column: 1 / -1;
  }

  &__align {
    &-self {
      &--center {
        place-self: center;
      }
    }

    &-cols {
      &--center {
        align-items: center;
      }
    }

    &-rows {
      &--center {
        justify-items: center;
      }
    }
  }

  &__row {
    &--1 {
      grid-row: 1;
    }

    &--2 {
      grid-row: 2;
    }

    &--3 {
      grid-row: 3;
    }

    &--4 {
      grid-row: 4;
    }
  }
}
