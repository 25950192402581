$white: #FFFFFF;

$text-blue: #305887;
$text-gray: #404B5B;
$text-light-gray: #202833;
$background-action: #F1F7FD;
$background-gray: #F5F5F9;
$border-gray: #DEDEDE;
$blue-light: #5E85B4;
$red: #f44336;
$red-light: #F8EBEB;
$red-dark: #410202;
$green: #00A438;
