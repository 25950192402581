.mat-icon:not(.mat-icon-inline) {
  svg {
    height: 24px;
    width: 24px;
  }

  &.size-16px {
    font-size: 16px;
    height: 16px;
    line-height: 16px;
    width: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.mat-icon-18 {
    width: 18px;
    height: 18px;
    line-height: 18px;

    svg {
      height: 18px;
      width: 18px;
    }
  }
}

.mat-step-icon-content .mat-icon svg {
  height: 100%;
  width: 100%;
}
